.ones-prize {
    /* border: 2px solid red;
    background-color: #fff;
    border-radius: 30px; */
    /* padding: 5px 20px; */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-content: center; 
    width: fit-content;
    /* margin: 5px auto 20px auto; */

    /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s; */
}

.two-prize {
    /* border: 2px solid red;
    background-color: #fff; */
    /* border-radius: 30px; */
    /* padding: 5px 20px; */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-content: center; 
    width: fit-content;
    /* margin: 5px auto 50px auto; */

    /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s; */
}

.bg {
    background-image: url('assets/images/bg.jpg');
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-size: auto 100%; */
}